import { createRouter, createWebHistory } from "vue-router";

import LayoutPC from "../layout/layout_base.vue";
import Home from '../pages/home.vue'

const routes = [
  {
    path: "/",
    component: LayoutPC,
    name: "layout",
    children: [
      {
        path: "/",
        name: "home",
        component: Home,
      },
      {
        path: "/installation",
        name: "Installation",
        component: () => import("../pages/install.vue"),
      },
      {
        path: "/talent",
        name: "Talent",
        component: () => import("../pages/hiring.vue"),
      },
      {
        path: "/talentDetail",
        name: "Details",
        component: () => import("../pages/hiringDetail.vue"),
      },
    ],
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return {
      top: 0,
    };
  },
});
export default router;
