<template>
<div class="nav_container">
  <div class="nav_wrap" :style="{padding: `${16*page_ratio}PX ${60*page_ratio}PX`}" :class="[moreScreenHeight ? 'header_light' : 'header_dark', slideUp ? 'header_transparent': '']">
    <div class="nav">
      <div class="nav_loge" @click="goHome" :style="{transform: `scale(${page_ratio})`, transformOrigin: 'left'}" >
        <div class="logo">
          <img class="logo-img" src="../../assets/mobile2/logo.png" alt="logo">
        </div>
        <div class="nav_logo_text">
          <span :class="slideUp ? 'nav_loge_ani_hide' : 'nav_loge_ani_show'" class="logo-text">
            <img v-if="moreScreenHeight" src="../../assets/v2/logo-text-black.svg" alt="">
            <img v-else src="../../assets/v2/logo-text.svg" alt="">
          </span>
        </div>
      </div>

      <div class="nav_list_wrap" :style="{transform: `scale(${page_ratio})`}">
        <ul class="nav_list" :class="slideUp ? 'nav_list_ani_hide' : 'nav_list_ani_show'">
          <li class="nav_list_li" :class="{'active_nav': activeNav === val.id}" v-for="val in NavList" :key="val.id" :data-to="val.id" @click="goMenu(val.id)">
            {{ val.name }}
          </li>
        </ul>
      </div>

      <div class="nav_right" :style="{transform: `scale(${page_ratio})`, transformOrigin: 'right'}">
        <div class="nav_github_text" >
          <div class="nav_github_target" :class="slideUp ? 'nav_github_ani_hide' : 'nav_github_ani_show'">
            <div class="nav_github" @click="goMenu(100)">
              <img v-if="moreScreenHeight" src="../../assets/v2/github-light.svg" alt="github">
            <img v-else src="../../assets/v2/github.svg" alt="github">
            <span :style="{color: moreScreenHeight ? '#232323' : '#ffffff' }">Github</span>
            </div>
            <Language :dark="!moreScreenHeight" :slideUp="slideUp"></Language>

          </div>
        </div>
        
        <div class="get_start" >
          <get_started :light="moreScreenHeight" @click="goMenu(200)"></get_started>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import get_started from "./get_started_mini.vue";
import { page_ratio } from "@/stores/platform";
import link from '@/common/link';
import Language from './language.vue';
const nav_height = computed(()=> 56 * page_ratio.value);

const {t} = useI18n();

const router =  useRouter();
let NavList = computed(() =>[
  {
    name: t('home'),
    id: 0
  },
  {
    name: t('docs'),
    id: 1
  },
  {
    name: t('market'),
    id: 2
  },
  {
    name: t('blog'),
    id: 3
  },
  {
    name: t('space'),
    id: 4
  },
]);
const activeNav = ref(0);
const slideUp = ref(false);
const moreScreenHeight = ref(false);
const starting = ref(0);

const windowScroll = () => {
  const screenHeight = document.documentElement.clientHeight;
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

  if (scrollTop > screenHeight) {
    moreScreenHeight.value = true;
  } else {
    moreScreenHeight.value = false;
  }

  let scroll = scrollTop - starting.value;
  starting.value = scrollTop;
  if (scroll > 0) {
    slideUp.value = true;
  } else {
    slideUp.value = false;
  }

  if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - nav_height.value) {
        moreScreenHeight.value = false;
    }
}

const goHome = () => {
  router.push("/")
}

const goMenu = (value) => {
  activeNav.value = value;
  if (value === 1) {
    window.open('https://docs.jointerminus.com/');
  } else if( value == 2 ) {
    window.open('https://market.jointerminus.com/');
  } else if( value == 3 ) {
    window.open(link.footer_medium);
  } else if( value == 4 ) {
    window.open('https://space.jointerminus.com/');
  } else if( value == 100 ) {
    window.open('https://github.com/beclab')
  } else if( value == 200 ) {
    window.open(link.get_started)
  }
}

onMounted(() => {
  window.addEventListener('scroll', windowScroll);
})

onUnmounted(() => {
  window.removeEventListener('scroll', windowScroll);
})

</script>

<style scoped>
.nav_container {
  position: sticky;
  top: 0PX;
  z-index: 100;
  width: 100vw;
  display: flex;
  justify-content: center;
  max-width: 100%;
}
.nav_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.header_light {
  background: #FFFFFF;
}
.header_light .nav_list_li {
  color: #232323;
}

.header_light .nav_loge span {
  color: #232323;
}

.header_light .get_start {
  background-color: #FFFFFF;
  color: #232323;
}



.header_dark {
  background: #09090B;
}

.header_dark .nav_list_li {
  color: #FFFFFF;
}

.header_dark .nav_loge span {
  color: #FFFFFF;
}

/* .header_dark .nav_loge .logo {
  background-color: #09090B;
} */

.header_dark .get_start {
  color: #FFFFFF;
}
.header_transparent {
  background: transparent;
}
.nav {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav_shadow {
  background-color: rgba(255, 255, 255, 0.96);
  box-shadow: 0px 1px 10px #e5e5e5;
}

.nav_loge {
  width: 200PX;
  height: 40PX;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.nav_loge .logo {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.nav_loge .logo img {
  height: 28PX;
  width: 29PX;
} 

.nav_logo_text {
  width: 82PX;
  height: 27PX;
  overflow: hidden;
  position: absolute;
  left: 38PX;
  top: 50%;
  transform: translateY(-50%);
}

.logo-text {
  font-family: Poppins;
  font-size: 18PX;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2PX;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.nav_loge_ani_hide {
  animation: logoHideAni .4s ease-in-out forwards;
}
@keyframes logoHideAni {
  0% {
    right: 0;
  }
  100% { 
    right: 150PX;
  }
}
.nav_loge_ani_show {
  animation: logoShowAni .4s ease-in-out forwards;
}

@keyframes logoShowAni {
  0% {
    right: 150PX;
  }
  100% { 
    right: 0;
  }
}

.nav_list_wrap {
  position: relative;
}

.nav_list {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.nav_list_ani_hide {
  animation: navAniHide .4s ease-in-out forwards;
}

@keyframes navAniHide {
  0% {
    top: 0;
  }
  100% { 
    top: -98PX;
  }
}

.nav_list_ani_show {
  animation: navAniShow .4s ease-in-out forwards;
}

@keyframes navAniShow {
  0% {
    top: -98PX;
  }
  100% { 
    top: 0;
  }
}


.nav_list_li {
  margin: 0 27PX;
  font-size: 14PX;
  cursor: pointer;
  height: 30PX;
  line-height: 30PX;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.active_nav {
  color: rgba(255, 91, 40, 1) !important;
}

.nav_right {
  position: relative;
  width: 264PX;
  height: 32PX;
}

.nav_github_text {
  width: 166PX;
  height: 32PX;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  cursor: pointer;
}

.nav_github_target {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  bottom: 0;
  margin: 0;
}
.nav_github {
  display: flex;
  align-items: center;
}
.nav_github span {
  font-size: 16PX;
  font-style: normal;
  font-weight: 500;
  line-height: 20PX;
  letter-spacing: 0.2PX;
  margin-left: 6PX;
}

.nav_github_ani_hide {
  animation: githubHideAni .4s ease-in-out forwards;
}
@keyframes githubHideAni {
  0% {
    left: 0;
    opacity: 1;
  }
  40% { 
    opacity: 0.2;
  }
  45% { 
    opacity: 0;
  }
  100% { 
    left: 174PX;
    opacity: 0;
  }
}
.nav_github_ani_show {
  animation: githubShowAni .4s ease-in-out forwards;
}

@keyframes githubShowAni {
  0% {
    left: 174PX;
    opacity: 0;
  }
  55% { 
    opacity: 0.2;
  }
  60% { 
    opacity: 1;
  }
  100% { 
    left: 0;
    opacity: 1;
  }
}

.get_start {
  box-sizing: border-box;
  font-size: 14PX;
  text-align: center;
  cursor: pointer;
  margin-left: 20PX;
  border-radius: 8PX;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  display: flex;
  align-items: center;
}
</style>