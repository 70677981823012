import link from '../common/link'

const en = {
  website_title: 'Terminus，您的专属家庭云 - 让您的数据重回掌控',
  get_start: '快速上手',
  product: '产品',
  resource: '资源',
  protocol: '协议',
  footer_dsc: '让人们重新拥有自己的数据',
  recommend: '推荐',
  home: "主页",
  docs: "文档",
  market: "市场",
  blog: "博客",
  space: "空间",
  get_started: "现在开始",
  page1_title: "What is Terminus?",
  terminus_dsc_1: "您的专属家庭云",

  terminus_dsc_1_1: "打造您的个人智能助理",
  terminus_dsc_2_1: "同步您散落各处的数据",
  terminus_dsc_3_1: "创建您的私有工作空间",
  terminus_dsc_4_1: "管理您的智能家居",
  terminus_dsc_5_1: "搭建您的私人媒体中心",

  page2_title:
    'The Evolution of the <span class="page1-title-highlight">Internet</span>',
  // page2_subtitle: `<a class="link-flash" href="${link.bec_introduction}" target="_blank">BEC Architecture</a> consists of three parts:
  //   <a class="link-flash" href="${link.snowinning_protocol}" target="_blank">Snowinning Protocol</a>, a smart contract
  //   running on blockchain. <a class="link-flash" href="${link.terminus_OS}" target="_blank">Terminus OS</a>, an
  //   operating system running on the personal edge server.
  //   <a class="link-flash" href="${link.termiPass}" target="_blank">TermiPass</a>, a client software running
  //   on the user's mobile phone and computer.`,
  // page2_subtitle: `<b>Blockchain Edge Client (BEC) Architecture</b>:
  // <br><b>Snowinning Protocol:</b> An identity and reputation protocol based on blockchain smart contracts.
  // <br><b>Terminus OS:</b> An operating system running on personal edge servers.
  // <br><b>TermiPass:</b> Client application that runs on users' phones and computers.`,
  page2_subtitle: "",
  page2_title_1: "Centralized Internet",
  page2_subtitle_1: "Client-Server Architecture",
  page2_dsc_1: `Users lack control over data, identity, and secrets. Companies manage accounts and store data on their servers.`,
  page2_title_2: "Internet of Data Sovereignty ",
  page2_subtitle_2: "Blockchain Edge Client Architecture",
  page2_dsc_2: `Applications and data reside at the edge. Secrets are stored on clients, identities on blockchain. <a class="link-flash link-flash-wrapper" href="${link.bec_introduction}" target="_blank">Learn More</a>`,
  page3_title: "Snowinning Protocol",
  // page3_subtitle: `By integrating Decentralized Identifiers, Verifiable Credentials and
  //   Decentralized Reputation into the blockchain smart contract.
  //   <a class="link-flash" href="${link.snowinning_protocol}" target="_blank">Snowinning Protocol</a> has created a decentralized identity and reputation system.`,
  page3_subtitle: `A decentralized identity and reputation system that integrates decentralized identifiers (DIDs), verifiable credentials (VCs), and reputation data into blockchain smart contracts. <a class="link-flash" href="${link.snowinning_protocol}" target="_blank">Learn More</a>`,
  desktop: "桌面",
  files: "文件",
  vault: "Vault",
  wise: "Wise",
  manage: "管理",
  develop: "开发",
  page4_title: "轻松上手，使用零门槛",
  page4_subtitle: "Terminus 内置了丰富的应用程序，使用起来像智能手机一样简单。",
  read_more: "更多",
  desktop_title: "AI 赋能，个性化桌面",
  desktop_subtitle_1:
    "<span class='page4-bolder'>内置启动器：</span>应用一触即发，AI助手加持，让您事半功倍。",
  desktop_subtitle_2:
    "<span class='page4-bolder'>个性化定制：</span>量身定制，让桌面彰显您的与众不同。",
  desktop_subtitle_3:
    "<span class='page4-bolder'>熟悉的界面：</span> 交互直观，驾轻就熟。",
  files_title: "您的数据港湾",
  files_subtitle_1:
    "<span class='page4-bolder'>随时随地访问：</span> 从任意设备或浏览器查看和共享您在 Terminus 中存储的文件。",
  files_subtitle_2:
    "<span class='page4-bolder'>同步无间：</span>保持数据在不同设备之间的无缝流转，确保访问流畅无阻。",
  files_subtitle_3:
    "<span class='page4-bolder'>集中存储：</span>将散落在网络上的数据集中存储于 Terminus，打造您的信息中枢。",
  vault_title: "您值得信赖的密码守护者",
  vault_subtitle_1:
    "<span class='page4-bolder'>安全且免费：</span>体验类似 1Password 的高级加密技术和密码管理功能，完全免费。",
  vault_subtitle_2:
    "<span class='page4-bolder'>DID & VC 管理：</span>轻松管理去中心化标识符（DID）和可验证凭证 (VC)，与全球发行者和验证者顺畅对接。",
  vault_subtitle_3:
    "<span class='page4-bolder'>跨平台统一体验：</span>在网页、移动端、桌面端和浏览器扩展程序中轻松访问和同步您的密码数据。",
  market_title: "用户掌控的应用生态",
  market_subtitle_1:
    "<span class='page4-bolder'>开放自由：</span>应用沙盒提供无需许可的应用生态系统。",
  market_subtitle_2:
    "<span class='page4-bolder'>统一账户体系：</span>使用单一账户无缝访问所有应用程序。",
  market_subtitle_3:
    "<span class='page4-bolder'>轻松部署：</span>一键安装，零配置零维护。",
  wise_title: "您的数字后花园",
  wise_subtitle_1:
    "<span class='page4-bolder'>AI 驱动，本地优先的阅读器：</span>轻松收集、阅读和管理来自不同平台的信息。",
  wise_subtitle_2:
    "<span class='page4-bolder'>定制化推荐：</span>通过自托管的推荐算法提供定制内容，摆脱信息茧房。",
  wise_subtitle_3: "<span class='page4-bolder'>应用集成：</span>开放 API，集成海量三方知识应用。",
  settings: "设置",
  settings_subtitle: "像电脑一样轻松管理您的 Terminus。",
  dashboard: "系统面板",
  dashboard_title: "时刻监控 Terminus 的运行。",
  profile: "Profile",
  profile_subtitle: "定制您的专属个人主页。",
  devbox: "Devbox",
  devbox_subtitle:
    "Terminus 提供开发、调试、部署一站式应用开发体验。",
  control_hub: "ControlHub",
  control_hub_subtitle:
    "抛开繁琐命令，无痛管理 Kubernetes 集群。",
  page5_title: "云技术，触手可及",
  page5_subtitle:
    "借助 Kubernetes 的强大功能，Terminus 将过去十年最前沿的开源与云原生技术带到您身边。",
  page5_dsc_1: "成本削减（与云端部署相比）",
  page5_dsc_2: "云原生开发体验",
  page5_dsc_3: "本地与云端混合部署",
  unlimited: "无限制",
  compute: "计算",
  storage: "存储",
  middleware: "中间件",
  page6_title: "安全大于一切",
  page6_subtitle:
    "Terminus 提供即开即用的企业级安全解决方案。",
  network: "网络配置",
  network_dsc:
    "从域名到安全软件，Terminus 处理好所有网络相关的配置。",
  service_grading: "按服务设置安全策略",
  service_grading_dsc:
    "开发者和用户可以灵活地为不同服务设置安全策略。",
  access_anywhere: "随时随地访问",
  access_anywhere_dsc:
    "通过 Tailscale，您可以在世界各地安全，快速访问 Terminus 上的私有服务。",
  edge_firewall: "Edge 端防火墙",
  edge_firewall_dsc:
    "通过 Cloudflare 隐藏公共服务，让 Terminus 实现企业级安全防护。",
  account: "账户管理",
  account_dsc:
    "将应用账户与 Terminus 集成，共享身份认证服务。",
  single_sign_On: "统一账户",
  single_sign_On_dsc:
    "一次登录，自由访问 Terminus 里所有应用。",
  MFA: "可配置多因素验证",
  MFA_dsc:
    "自由配置多因素验证策略，为敏感操作提供额外保护。",
  developer_friendly: "开发者友好",
  developer_friendly_dsc:
    "详实的文档指导，让开发者快速集成第三方应用至 Terminus。",
  application_sandbox: "应用生态",
  application_sandbox_dsc:
    "通过应用沙盒技术，Terminus 提供了一个真正独立的第三方应用商店。",
  safe_isolation: "安全隔离",
  safe_isolation_dsc:
    "针对系统和第三方应用，实现了网络、存储、中间件的多层级隔离。",
  resource_limits: "资源限制",
  resource_limits_dsc:
    "用户可以灵活定义应用需要的资源。操作系统也会及时终止资源使用异常的应用。",
  cluster_application: "集群应用",
  cluster_application_dsc:
    "用户可以通过共享集群类型的应用实现协作。",
  secret_management: "密钥管理",
  secret_management_dsc:
    "通过基于身份的安全手段，统一管理应用程序的凭据和密码。",
  security_shift_left: "自动化安全流程",
  security_shift_left_dsc:
    '预防优先，内置加密工具 Infisical，提升安全防护等级。',
  integrated: "轻松集成",
  integrated_dsc:
    "与 Terminus 上的三方应用和工作流轻松集成。",
  stay_in_control: "精细权限控制",
  stay_in_control_dec:
      "为用户和系统设置严格细致的权限。",
  page7_title: "无需许可，协议驱动的应用生态",
  page7_market_dsc:
    "一键安装第三方应用、推荐算法、大语言模型和 AI Agent。",
  distribute: "应用分发",
  distribute_dsc: `Terminus 基于无需许可的协议分发应用。`,
  page7_develop_dsc:
    "使用 DevBox 调试和开发 Terminus 应用。",
  page7_market: '应用安装',
  page7_develop: '应用开发',
  page8_title: "自托管的团队生产力解决方案",
  page8_dsc:
    "聊天、会议、日历等功能即将推出。",
  page9_title: "精彩回忆，随心存取",
  page9_subtitle:
    "让 AI 帮您存储并打理零散的数据。",
  sync: "同步",
  sync_dsc:
    "将您散落在网络各处的宝贵文档、照片、视频和聊天记录自动同步至 Terminus。",
  browser: "浏览",
  browser_dsc:
    "使用内置或第三方应用查看、编辑、管理不同渠道的数据，并在 Terminus 里沉淀为知识。",
  ask: "搜索",
  ask_dsc:
    "通过智能搜索快速定位数据，并利用私有数据增强本地 AI Agent 的能力。",
  store: "存储",
  store_dsc:
    "在Terminus 上安全高效地存储和备份您的数据。",
  page10_title: "本地大模型<br/>赋能与安全并行",
  page10_subtitle: `在本地用全球顶尖的开源大模型处理文本、图像和音频，与您的数据、应用程序及 AI Agent 无缝集成。`,
  page11_title: "更懂您的 AI 助手",
  page11_subtitle:
    "隐私无忧，定制自如，易于集成。",
  build_agent: "零代码制作 Agent",
  build_agent_dsc: `通过本地托管的 AI 工作流构建软件对 Agent 进行编排，集成本地的知识库和应用工具。您也可以通过应用市场直接安装配置好的 Agent。`,
  seamless_integration_knowledge: "集成本地个人知识库",
  seamless_integration_knowledge_dsc:
    "作为你的第二大脑和数字分身，AI Agent 可基于您的本地文档、照片、视频、聊天记录、健康记录等私有数据给出回答。",
  complete_jobs: " 自动化您的日常工作",
  complete_jobs_dsc:
    "让 Agent 根据您的行程自主预定酒店和航班吧！获得授权后，Agents 可以调用系统里的数据和应用自动完成任务。",
  agent_service: "Agent 服务化",
  agent_service_dsc:
    "通过 Otmoic 协议，让不同用户的 Agent 自动议价、买卖 AI 服务和硬件资源。",
  page12_title: "专属家庭娱乐中心，畅享不停歇",
  page12_subtitle:
    "丰富应用，全方位满足您的娱乐需求。",
  movies_shows: "影视",
  game: "游戏",
  music: "音乐",
  VR_streaming: "VR & AR",
  movies_shows_title: "免费的媒体系统",
  movies_shows_subtitle:
    "Jellyfin 是由社区贡献者共同打造的媒体解决方案。自主配置，随心串流，让每一台设备都成为您的私人影院。",
  game_title: "畅玩云游戏",
  game_subtitle: `接入 Terminus，自托管云游戏服务让您在任意设备上畅快开玩。`,
  music_title: "音乐新发现",
  music_subtitle:
    "让 Navidrome 音乐服务器整合您不同设备的音乐收藏，跨设备纵享高品质音乐体验，探索、发现、沉浸——随时随地。",
  VR_streaming_title: "沉浸虚拟世界",
  VR_streaming_subtitle:
    "Terminus 为 VR & AR 游戏调度必要的 GPU 资源，极致体验待您解锁。",
  learn_more: "更多",
  page13_title: "掌控您的智能家居",
  page13_subtitle:
    "跨品牌智能家居设备本地统一管理，不依赖任何云服务。隐私安全有保障，智能生活更放心。",
  page14_title: "您的社交，由您主宰",
  page14_subtitle:
    "在 Terminus 上安装去中心化社交应用，直接与粉丝互动。无审查、无费用，让交流更真实纯粹。",
  ghost: "Ghost",
  ghost_title: "新时代独立出版应用",
  ghost_subtitle:
    "专为内容创造者设计的强大应用。使用 Ghost，从创作、建站、分享到营销，尽可能释放内容的潜力。",
  mastodon: "Mastodon",
  mastodon_title: `不以盈利为目的的社交平台。`,
  matrix: "Matrix",
  matrix_title: "开放的安全去中心化通讯网络。",
  page15_title: "Decentralized Information Distribution Protocol",
  page15_subtitle:
    'By establishing a decentralized protocol for information distribution, we can reclaim our "feeds" from the hands of tech giants.',
  new_paradigm_recommendation: "A New Paradigm for Recommendation",
  new_paradigm: "New Paradigm",
  new_paradigm_dsc:
    "By dividing the algorithm into two phases, we have crafted a recommendation engine protocol designed with privacy protection in mind.",
  open: "Open",
  open_dsc:
    "Algorithm providers can submit their algorithms to the market, where users can install any third-party algorithm.",
  local_recommend_framework: "Local Recommend Framework",
  privacy_preserving: "Privacy Preserving",
  privacy_preserving_dsc:
    "Since each Terminus receives the same data from the Cloud and the recommendation algorithm operates in a network-free environment, user feedback data is maintained with confidentiality.",
  composable_workflow: "Composable Workflow",
  composable_workflow_dsc:
    "Developers can freely orchestrate processes such as recommendations and training across different stages of the algorithm.",
  model_enhancement_network: "Model Enhancement Network",
  federated_learning: "Federated Learning",
  federated_learning_dsc:
    "With user consent, Terminus is able to engage in the iterative improvement of algorithms through federated learning and receive token incentives.",
  POI: "POI（Proof of Intelligent）",
  POI_dsc:
    "We aim to design a proof-of-intelligent system that fairly rewards users who provide gradient data for training models.",
  page16_title: "Give Public Goods a Fair Price",
  page16_subtitle:
    `Otmoic Protocol is an automated value exchange network built for DePin, AI Agents and Crypto Trading, without the need for third-party trust. <a class="link-flash" href="${link.otmoic_protocol}" target="_blank">Learn More</a> `,
  reputation: "Reputation",
  reputation_dsc_1:
    "Records reputation history in smart contracts.",
  reputation_dsc_2:
    "Utilize KYC methods based on Verifiable Credentials to understand your counterparties when necessary.",
  RFQ: "RFQ",
  RFQ_dsc:
    "Solving the Free Mint problem by incorporating a confirmation phase.",
  numerous_application_scenarios: "Numerous application scenarios",
  automated_market_maker: "Automated Market Maker",
  automated_market_maker_dsc:
    "Anyone can become a market maker by installing the Otmoic application in Terminus.",
  atomic_swap: "Atomic Swap",
  atomic_swap_dsc:
    "Process on-chain and cross-chain transactions using Atomic Swaps, eliminating the need for a trusted third party.",
  page17_title: "衷心感谢以下开源项目",
  page18_title: "准备好重新掌控您的数据了吗？",
};

export default en;
