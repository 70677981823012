const docsHost = "https://docs.jointerminus.com";
const marketHost = "https://market.jointerminus.com";

export default {
  // page1
  get_started: `${docsHost}/overview/introduction/getting-started/`,

  //   page2
  bec_introduction: `${docsHost}/overview/introduction/bec.html`,
  snowinning_protocol: `${docsHost}/overview/snowinning/overview.html`,
  terminus_OS: `${docsHost}/overview/terminus/overview.html`,
  termiPass: `${docsHost}/how-to/termipass/overview.html`,
  snowinning_protocol_read_more: `${docsHost}/overview/snowinning/overview.html`,

  // page4
  desktop_learn_more: `${docsHost}/how-to/terminus/desktop.html`,
  files_learn_more: `${docsHost}/how-to/terminus/files/`,
  vault_learn_more: `${docsHost}/how-to/terminus/vault/`,
  market_learn_more: `${docsHost}/how-to/terminus/market/`,
  wise_learn_more: `${docsHost}/how-to/terminus/wise/`,
  settings_learn_more: `${docsHost}/how-to/terminus/settings/`,
  dashboard_learn_more: `${docsHost}/how-to/terminus/dashboard/`,
  profile_learn_more: `${docsHost}/how-to/terminus/profile.html`,
  devbox_leanr_more: `${docsHost}/developer/develop/tutorial/devbox.html`,
  controlHub_leanr_more: `${docsHost}/how-to/terminus/controlhub/`,

  //   page6
  network_learn_more: `${docsHost}/overview/terminus/network.html`,
  account_learn_more: `${docsHost}/overview/terminus/account.html`,
  application_sandbox_learn_more: `${docsHost}/overview/terminus/application.html`,
  secret_management_learn_more: `${docsHost}/overview/terminus/secret.html`,

  // page7
  market_learn_more_2: `https://market.jointerminus.com/`,
  distribute_learn_more: `https://github.com/beclab/apps`,
  develop_learn_more: `${docsHost}/developer/develop/tutorial/devbox.html`,

  //   page11
  AI_assistant_learn_more: `${docsHost}/overview/terminus/ai.html`,

  //   page12
  movies_learn_more: `${marketHost}/app/jellyfin`,
  music_learn_more: `${marketHost}/app/navidrome`,

  //   page13
  Home_Assitant: `${marketHost}/app/homeassistant`,

  //   page14
  ghost: `${marketHost}/app/ghost`,
  mastodon: `${marketHost}/app/mastodon`,

  //   page15
  distribution_protocol_learn_more: `${docsHost}/overview/protocol/recommend.html`,

  //   page16
  otmoic_protocol: `${docsHost}/overview/protocol/otmoic.html`,

  // page17. Special thanks

  kubesphere: "https://kubesphere.io/",
  kubernetes: "https://kubernetes.io/",
  juicefs: "https://juicefs.com/en/",
  headscale: "https://headscale.net/",
  tailscale: "https://tailscale.com/",
  dify: "https://dify.ai/",
  seafile: "https://www.seafile.com/en/home/",
  redis_operator: "https://github.com/spotahome/redis-operator",
  jan: "https://jan.ai/",
  nitro: "https://nitro.jan.ai/",
  rss: "https://rss.com/",
  predixy: "https://github.com/joyieldInc/predixy",
  mongodb: "https://docs.percona.com/percona-operator-for-mongodb/index.html",
  nvshare: "https://github.com/grgalex/nvshare",
  infisical: "https://infisical.com/",
  langchain: "https://www.langchain.com/",
  quasar: "https://quasar.dev/",
  padloc: "https://padloc.app/",
  trustwallet: "https://trustwallet.com/",
  envoyproxy: "https://www.envoyproxy.io/",
  restic: "https://restic.net/",
  zincsearch: "https://zincsearch-docs.zinc.dev/",
  k3s: "https://k3s.io/",
  authelia: "https://www.authelia.com/",
  filebrowser: "https://filebrowser.org/",
  lego: "https://go-acme.github.io/lego/",
  velero: "https://velero.io/",
  s3rver: "https://github.com/jamhall/s3rver",
  citusdata: "https://www.citusdata.com/",


  //   page18
  footer_get_started: `${docsHost}/overview/introduction/getting-started/`,
  footer_github: "https://github.com/beclab",
  footer_discord: "https://discord.com/invite/BzfqrgQPDK",
  footer_twitter: "https://twitter.com/TerminusOS",
  footer_medium: "https://jointerminus.medium.com",
  footer_terminus_os: "https://github.com/beclab/terminus",
  footer_termi_pass: "https://github.com/beclab/TermiPass",
  footer_terminus_space: "https://space.jointerminus.com/",
  footer_snowinning: `${docsHost}/overview/snowinning/overview.html`,
  footer_otmoic: `${docsHost}/overview/protocol/otmoic.html`,
  footer_market: `${docsHost}/overview/protocol/market.html`,
  footer_recomend: `${docsHost}/overview/protocol/recommend.html`,
  footer_docs: `${docsHost}/overview/introduction/what-is-terminus.html`,
};
