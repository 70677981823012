<template>
  <div class="page18">
    <div class="page18-top split" :style="{paddingBottom: `calc(105PX * ${page_ratio})`}">
      <div>
        <div class="page18-title">{{ $t("page18_title") }}</div>
        <div class="page18-button">
          <a
            :href="link.footer_get_started"
            target="_blank"
            rel="noopener noreferrer"
          >
            <get_started></get_started>
          </a>
          <a
            class="github-button-container"
            :href="link.footer_github"
            target="_blank"
            rel="noopener noreferrer"
            @mouseenter="mouseenterHandler1(index)"
            @mouseleave="mouseleaveHandler1(index)"
          >
          <img :src="activeHover ? githubIcon : githubIcon2" >
          <a class="github-button">Github</a>
          </a>
        </div>
      </div>
    </div>
    <div class="page18-bottom">
      <div class="bottom-content">
        <div>
          <div class="logo-container">
            <img class="log-img" src="../../assets/v2/logo.svg" alt="" />
            <span class="logo-name">Terminus</span>
          </div>
          <div class="opacity-text left-text">
            {{ $t('footer_dsc') }}
          </div>
          <div class="link-container">
            <a
              :href="link.footer_github"
              target="_blank"
              rel="noopener noreferrer"
              @mouseenter="mouseenterHandler(0)"
              @mouseleave="mouseleaveHandler"
            >
              <img :src="active === 0 ? icon1Active : icon1" alt="" />
            </a>
            <span
              class="wechat-popup"
              @mouseenter="mouseenterHandler(5)"
              @mouseleave="mouseleaveHandler"
              v-if="lang_zh"
            >
              <img :src="active === 5 ? icon5Active : icon5" alt="" />
              <div class="qr-container">
                <div class="qr-wrapper">
                  <img class="qr-img" src="../../assets/v2/wechatQR.jpg" alt="">
                </div>
              </div>
          </span>
            <a
              :href="link.footer_discord"
              target="_blank"
              rel="noopener noreferrer"
              @mouseenter="mouseenterHandler(1)"
              @mouseleave="mouseleaveHandler"
              v-if="!lang_zh"
            >
              <img
                class="link-2"
                :src="active === 1 ? icon2Active : icon2"
                alt=""
              />
            </a>
            <a
              :href="link.footer_twitter"
              target="_blank"
              rel="noopener noreferrer"
              @mouseenter="mouseenterHandler(2)"
              @mouseleave="mouseleaveHandler"
              v-if="!lang_zh"
            >
              <img
                class="link-2"
                :src="active === 2 ? icon3Active : icon3"
                alt=""
              />
            </a>
            <a
              :href="link.footer_medium"
              target="_blank"
              rel="noopener noreferrer"
              @mouseenter="mouseenterHandler(3)"
              @mouseleave="mouseleaveHandler"
            >
              <img
                class="link-2"
                :src="active === 3 ? icon4Active : icon4"
                alt=""
              />
            </a>
          </div>
          <div class="opacity-text info" v-if="!lang_zh">@2024 BEC LAB</div>
        </div>
        <div class="footer-item-container">
          <div class="footer-item-layout">
            <div class="footer-item-title">{{ $t('product') }}</div>
            <a class="opacity-text footer-item-text link-flash2" target="_blank" :href="link.footer_terminus_os">Terminus</a>
            <a class="opacity-text footer-item-text link-flash2" target="_blank" :href="link.footer_terminus_space">Terminus Space</a>
            <a class="opacity-text footer-item-text link-flash2" target="_blank" :href="link.footer_termi_pass">TermiPass</a>
          </div>
          <div class="footer-item-layout" v-if="!lang_zh">
            <div class="footer-item-title">{{ $t('protocol') }}</div>
            <a
              class="opacity-text footer-item-text link-flash2"
              :href="link.footer_snowinning"
              target="_blank"
              >Snowinning</a
            >
            <a
              class="opacity-text footer-item-text link-flash2"
              :href="link.footer_otmoic"
              target="_blank"
              >Otmoic</a
            >
            <a
              class="opacity-text footer-item-text link-flash2"
              :href="link.footer_market"
              target="_blank"
              >{{ $t('market') }}</a
            >
            <a
              class="opacity-text footer-item-text link-flash2"
              :href="link.footer_recomend"
              target="_blank"
              >{{ $t('recommend') }}</a
            >
          </div>
          <div class="footer-item-layout">
            <div class="footer-item-title">{{ $t('resource') }}</div>
            <a
              class="opacity-text footer-item-text link-flash2"
              :href="link.footer_docs"
              target="_blank"
              >{{ $t('docs') }}</a
            >
          </div>
          <!-- <div class="footer-item-layout footer-item-layout2">
            <div class="footer-item-title">Company</div>
            <div class="opacity-text footer-item-text2">
              <div class="item-child">
                <img src="@/assets/v2/local.svg" alt="" />
                <div class="item-text">
                  8502 Preston Rd. Inglewood, Maine 98380, USA
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import get_started from "./get_started.vue";
import link from "@/common/link";
import icon1Active from "../../assets/v2/github-circle2.svg";
import icon2Active from "../../assets/v2/temp-circle2.svg";
import icon3Active from "../../assets/v2/twiter-circle2.svg";
import icon4Active from "../../assets/v2/bg-circle2.svg";
import icon5Active from "../../assets/v2/wechat-circle2.svg";

import icon1 from "../../assets/v2/github-circle.svg";
import icon2 from "../../assets/v2/temp-circle.svg";
import icon3 from "../../assets/v2/twiter-circle.svg";
import icon4 from "../../assets/v2/bg-circle.svg";
import icon5 from "../../assets/v2/wechat-circle.svg";
import { ref } from "vue";
import githubIcon from '../../assets/v2/github-icon.svg';
import githubIcon2 from '../../assets/v2/github-icon2.svg';
import { page_ratio } from "@/stores/platform";
import { lang_zh } from '@/utils/lang';

const active = ref(-1);
const activeHover = ref(-1)
const mouseenterHandler = (index) => {
  active.value = index;
};

const mouseleaveHandler = () => {
  active.value = -1;
};
const mouseenterHandler1 = (index)=> {
  activeHover.value = index;
}

const mouseleaveHandler1 = ()=> {
  activeHover.value = -1;
}
</script>

<style scoped>
.page18 {
  width: 100%;
  padding-top: 56PX;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page18-top {
  flex: 1;
  background: #0d0d0d url(@/assets/v2/footer-bg.svg);
  background-position: 50% 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page18-title {
  color: var(--www-risecalendar-com-nero, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 52PX;
  font-style: normal;
  font-weight: 400;
  line-height: 72PX; /* 138.462% */
  letter-spacing: -1.1PX;
}
.page18-button {
  flex: 1;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 48PX;
  gap: 32PX;
}
.page18-img-right {
  margin-left: 32PX;
}
.github-button-container {
  display: flex;
  width: 168PX;
  height: 56PX;
  padding: 16PX 35PX;
  justify-content: center;
  align-items: center;
  color: #000;
  gap: 8PX;
  flex-shrink: 0;
  border-radius: 8PX;
  background: var(--www-risecalendar-com-nero, #fff);
  box-shadow: 0PX 1PX 2PX 0.5PX rgba(0, 0, 0, 0.1),
    0PX 1PX 4PX 0PX rgba(0, 0, 0, 0.05), 0PX 0PX 1PX 0PX rgba(0, 0, 0, 0.16);
  cursor: pointer;
}
.github-button-container:hover {
  color: #fff;
  background: linear-gradient(90deg, #ff7b01 0%, #ff4e42 46.71%, #8dbbff 100%);
}
.github-button-container:hover .github-button{
  color: #fff;
}
.github-button {
  text-align: center;
  font-family: Inter;
  font-size: 20PX;
  font-style: normal;
  font-weight: 500;
  line-height: 20PX; /* 100% */
  letter-spacing: -0.36PX;
}
.split {
  width: 100%;
  border-bottom: 1PX solid var(--www-risecalendar-com-shark, #2f3034);
}
.page18-bottom {
  flex: 1;
}
.bottom-content {
  padding-top: 64PX;
  display: flex;
  justify-content: space-between;
}
.logo-container {
  display: flex;
  align-items: center;
}
.log-img {
  width: 28PX;
}
.logo-name {
  color: #fff;
  font-family: Poppins;
  font-size: 20PX;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.188PX;
  margin-left: 10PX;
}
.opacity-text {
  color: #fff;
  font-family: Inter;
  font-size: 14PX;
  font-style: normal;
  font-weight: 400;
  line-height: 18PX;
  letter-spacing: -0.3PX;
  opacity: 0.5;
  line-height: 20PX; /* 142.857% */
}
.left-text {
  margin-top: 4PX;
}
.link-container {
  margin-top: 42PX;
  display: flex;
  gap: 24PX;
}
.link-container img {
  width: 32PX;
  height: 32PX;
}
.link-2 {
}
.info {
  margin-top: 16PX;
}
.footer-item-container {
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  justify-content: flex-end;
}
.footer-item-layout {
  margin-left: 40PX;
  display: flex;
  flex-direction: column;
}
.footer-item-title {
  color: var(--www-risecalendar-com-nero, #fff);
  font-family: Inter;
  font-size: 14PX;
  font-style: normal;
  font-weight: 500;
  line-height: 20PX; /* 142.857% */
  letter-spacing: -0.3PX;
  padding: 8PX;
}
.footer-item-text {
  padding: 8PX;
  width: 132PX;
}
.footer-item-text2 {
  padding: 8PX;
}
.item-child {
  display: flex;
  justify-content: center;
  white-space: nowrap;
}
.item-child img {
  margin-right: 12PX;
}
.item-text {
  flex: 1;
  white-space: normal;
  width: 230PX;
}
.wechat-popup {
  position: relative;
}
.wechat-popup:hover .qr-container {
  display: block;
}
.qr-container {
  display: none;
  padding-bottom: 8PX;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
}
.qr-wrapper {
  padding: 12PX;
  border-radius: 12PX;
  background: #fff;
}
img.qr-img {
  width: 96PX;
  height: 96PX;
}
</style>
