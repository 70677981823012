<template>
  <div class="page9">
    <Card :title="$t('page9_title')" :sub-title="$t('page9_subtitle')"></Card>
    <div class="page9-content">
      <div class="page9-card active">
        <div class="right-top-title">
          <img class="top-title-icon" v-lazy="icon1" alt="" />
          <span class="top-title-text">{{ $t("sync") }}</span>
        </div>
        <div class="top-sub-title">
          {{ $t("sync_dsc") }}
        </div>
        <img class="card-bg-1" v-lazy="syncBG1" alt="" />
      </div>

      <div class="page9-card expand" @click="() => (active = 1)">
        <div class="right-top-title">
          <img class="top-title-icon" v-lazy="icon2" alt="" />
          <span class="top-title-text">{{ $t("browser") }}</span>
        </div>
        <div class="top-sub-title">
          {{ $t("browser_dsc") }}
        </div>
        <img class="card-bg" v-lazy="syncBG2" alt="" />
      </div>
      <div class="page9-card expand" @click="() => (active = 2)">
        <div class="right-top-title">
          <img class="top-title-icon" v-lazy="icon3" alt="" />
          <span class="top-title-text">{{ $t("ask") }}</span>
        </div>
        <div class="top-sub-title">
          {{ $t("ask_dsc") }}
        </div>
        <img class="card-bg" v-lazy="syncBG3" alt="" />
      </div>
      <div class="page9-card expand" @click="() => (active = 3)">
        <div class="right-top-title">
          <img class="top-title-icon" v-lazy="icon4" alt="" />
          <span class="top-title-text">{{ $t("store") }}</span>
        </div>
        <div class="top-sub-title">
          {{ $t("store_dsc") }}
        </div>
        <object class="card-bg" type="image/svg+xml" :data="locale === 'en' ? syncBG4 : syncBG4_zh" width="100%" ></object>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import Card from "../common/CardMobile.vue";
import syncBG1 from "../../assets/v2/sync-bg-1.webp";
import syncBG2 from "../../assets/v2/sync-bg-2.webp";
import syncBG3 from "../../assets/v2/sync-bg-3.webp";
import syncBG4 from "../../assets/v2/sync-bg-4.svg";
import syncBG4_zh from "../../assets/zh/sync-bg-4.svg";

import icon1 from "../../assets/sync.svg";
import icon2 from "../../assets/browser.svg";
import icon3 from "../../assets/ask.svg";
import icon4 from "../../assets/store.svg";

import { useI18n } from "vue-i18n";

const { locale } = useI18n();
let active = ref(0);
</script>

<style scoped>
.page9 {
  width: 100%;
}
.page9-content {
  display: flex;
  flex-direction: column;
  transition: all 200ms ease-in-out 50ms;
  opacity: 1;
  gap: 20px;
}
.page9-card {
  position: relative;
  padding: 32px;
  height: 480px;
  flex-shrink: 0;
  border: 1px solid #d6d6d6;
  background: drop-shadow(14px 17px 40px rgba(112, 144, 176, 0.08));
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  transition: all 100ms linear;
}

.page9-card-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.page9-card-reset {
  display: block;
}

.expand {
  /* width: 380PX; */
}

.card-bg {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
}
.card-bg-1 {
  position: absolute;
  bottom: -61px;
  left: -3px;
  right: 0px;
  width: calc(100% + 4px);
}

.right-top-title {
  display: flex;
  align-items: center;
}
.top-title-icon {
  width: 40px;
  height: 40px;
}

.top-title-text {
  color: #232323;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  text-transform: capitalize;
  margin-left: 12px;
}
.top-sub-title {
  margin-top: 12px;
  color: #706d79;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-top: 16px;
}
</style>
